.ChapterTaskItem-wrapper {
  margin: 1.5rem 0 0.5rem 0;
}

.ChapterTaskItem-wrapper .ui.checkbox input.hidden + label,
.ChapterTaskItem-wrapper .ui.checkbox label {
  color: white;
}

.ChapterTaskItem-wrapper .ui.checkbox input.hidden + label:hover {
  color: #6fbe44;
}

@media only screen and (max-width: 767px) {
  .ChapterTaskItem-wrapper .ui.checkbox label::before {
    top: 3px;
  }
}
