/* responsive iframe wrapper */
.Player-video-container {
  position: relative;
  /* this  makes the container 16:9 ratio */
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}

/* responsive iframe */
.Player-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
