.ui.items .EpisodeItem.item {
  margin-top: 0;
  margin-bottom: 2rem;
}

.ui.items .EpisodeItem.item .EpisodeItem-segment {
  margin-right: 2rem;
  margin-bottom: 0;
  padding: 0;
}

.ui.items
  .EpisodeItem.item
  .ui.bottom.attached.label:first-child
  ~ :last-child:not(.attached) {
  margin-bottom: 0 !important;
}

.ui.items .EpisodeItem.item .EpisodeItem-image {
  margin-right: 0;
}

.ui.items .EpisodeItem.item .EpisodeItem-label {
  margin-bottom: 0;
  z-index: 100;
  background-color: transparent;
  color: white;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  .ui.items
    .EpisodeItem.item
    .ui.bottom.attached.label:first-child
    ~ :last-child:not(.attached) {
    margin-bottom: 1rem !important;
  }

  .ui.items .EpisodeItem.item .EpisodeItem-image {
    width: 100%;
  }

  .ui.items .EpisodeItem.item .EpisodeItem-segment {
    margin-right: 0;
  }

  .ui.items .EpisodeItem.item .EpisodeItem-label {
    /* bottom: 0.5em;
    padding: 0.5em; */
  }
}
