.ChapterItem {
  cursor: pointer;
}

.ChapterItem-NumberNormal {
  position: absolute;
  z-index: 1;
  display: flex;
  background: rgba(5, 186, 229, 0.7);
  color: #fff;
  width: 90px;
  height: 90px;
  bottom: 25px;
  right: 25px;
  align-items: center;
  border-radius: 50%;
  font-size: 3em;
  justify-content: center;
  font-family: 'Signika Negative';
  font-weight: 600;
}

.ChapterItem-NumberCurrent {
  position: absolute;
  z-index: 1;
  display: flex;
  color: #fff;
  width: 90px;
  height: 90px;
  bottom: 25px;
  right: 25px;
  align-items: center;
  font-size: 3em;
  justify-content: center;
  font-family: 'Signika Negative';
  font-weight: 600;
  border-radius: 50% 0% 50% 50%;
  background: #6fbe44;
}

@media only screen and (max-width: 767px) {
  .ChapterItem-NumberCurrent,
  .ChapterItem-NumberNormal {
    top: 60px;
    left: 15px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 991px) {
  .ChapterItem-NumberCurrent,
  .ChapterItem-NumberNormal {
    top: 25px;
    left: 80px;
    width: 45px;
    height: 45px;
  }
}

@media only screen and (min-width: 991px) and (max-width: 1200px) {
  .ChapterItem-NumberCurrent,
  .ChapterItem-NumberNormal {
    top: 25px;
    left: 100px;
    width: 65px;
    height: 65px;
  }
}
