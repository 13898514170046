.ui.container.ChapterTasks {
  background-color: #29303b;
}

.ui.container.ChapterTasks .header {
  color: white;
}

.ui.container.ChapterTasks .ChapterTasksDetail {
  padding-left: 1.25rem;
  padding-bottom: 1.25rem;
  padding-right: 1.25rem;
}

.ui.container.ChapterTasks .segment {
  background-color: #29303b;
}
