.ui.items .ShowItem.item {
  margin-top: 0;
  margin-bottom: 2rem;
}

.ui.items .ShowItem.item .ShowItem-segment {
  margin-right: 2rem;
  margin-bottom: 0;
  padding: 0;
}

.ui.items .ShowItem.item .ShowItem-image {
  margin-right: 0;
  cursor: pointer;
  margin-bottom: 0 !important;
}

@media only screen and (max-width: 767px) {
  .ui.items .ShowItem.item .ShowItem-image {
    width: 100%;
    margin-bottom: 1rem !important;
  }

  .ui.items .ShowItem.item .ShowItem-segment {
    margin-right: 0;
  }
}
