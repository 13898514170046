.SecondaryActionLink {
  font-size: '1.3125rem';
}

.SecondaryActionLink a,
.SecondaryActionLink a:visited {
  color: rgba(0, 0, 0, 0.4);
}

.SecondaryActionLink a:active,
.SecondaryActionLink a:hover {
  color: #43bae4;
}
