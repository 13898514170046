.StripeElement {
  display: block;
  margin: 0em;
  outline: none;
  -webkit-appearance: none;
  tap-highlight-color: rgba(255, 255, 255, 0);
  line-height: 1.2;
  padding: 0.67em 1em;
  background: #ffffff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  -webkit-box-shadow: 0em 0em 0em 0em transparent inset;
  box-shadow: 0em 0em 0em 0em transparent inset;
  -webkit-transition: color 0.1s ease, border-color 0.1s ease;
  -o-transition: color 0.1s ease, border-color 0.1s ease;
  transition: color 0.1s ease, border-color 0.1s ease;
}

.StripeElement--focus {
  border-color: #85b7d9;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.8);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui.form .CreditCardForm-dropdown .divider {
  margin: 0;
}