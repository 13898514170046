.Footer {
  padding: 2em 0em;
  border-top: 1px solid rgba(34, 36, 38, 0.15) !important;
}

.Footer-margins {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .Footer-margins {
    /* left and right pad on mobile */
    margin: 0 2rem;
  }
}

.Footer .Footer-margins a,
.Footer .Footer-margins a:visited {
  color: rgba(0, 0, 0, 0.4);
}

.Footer .Footer-margins a:active,
.Footer .Footer-margins a:hover {
  color: #43bae4;
}
