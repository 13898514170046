.ui.vertical.segment .SignUp-Segment-Padded {
  padding-top: 4em;
  padding-bottom: 4em;
}

.ui.vertical.segment .SignUp-Segment-Grey {
  background-color: #f1f1f1;
}

.ui.container.SignUp-Container-Narrow {
  max-width: 927px !important;
}
